<template>
	<div style="background: #f5f5f5; padding-bottom: 30px" :style="{ height: containerHeight + 60 }">
		<qy-top-nav title="订单详情" />
		<div v-if="showOverTime" style="margin: 10px 0; font-size: 13px; padding: 5px 0px; background-color: #ffd8d8; text-align: center; color: #333; border-radius: 20px">
			<span style="color: red" v-text="overTimeText"></span>
			后订单将自动取消
		</div>
		<div style="display: flex; align-items: center; background: #fff; padding: 6px 0; color: #333" @click="$router.push('/proxyStg')">
			<img src="../../assets/img/add.png" alt="" class="addimg" style="width: 25px; margin-right: 2px; padding: 10px 4px 10px 12px" />
			<div style="background-color: #fff;color: #333;border-top: 0 solid #ededed;padding: 12px;font-weight: bold;}">
				
				<!-- <span><van-icon name="friends-o" />切换</span> -->
				<span>{{order.consigneeName}} {{order.consigneePhone}}</span>
				
				<div>{{order.consigneeAddress}}</div>
			</div>
		</div>

		

		<div style="margin: 12px 5px; border-radius: 5px; background: #fff; padding-bottom: 12px">
			<template v-if="order.goodsList && order.goodsList.length > 0">
				<div
					style="font-size: 16px; font-weight: bold; padding: 12px 0; text-align: center; border-bottom: 1px solid #ededed; margin-bottom: 10px; color: red"
					v-if="isProxy() && !selfOrder"
				>
					<i class="van-icon van-icon-manager prt2" />
					{{ orderUser.userName }}({{ orderUser.userPhone }})
				</div>
				<div style="line-height: 50px; font-size: 18px; font-weight: bold; color: #333; padding-left: 5px; color: red">
					<i class="van-icon van-icon-send-gift prt2" />
					{{ order.shopName || '平台' }}
					<!-- <i class="van-icon van-icon-arrow prt2" /> -->
					<span style="float: right; color: orange; margin-right: 20px">{{ statusDesc[order.status] }}</span>
				</div>
				<div style="border-top: 1px solid #ededed; padding-top: 6px">
					<div
						v-for="(item, goodsIndex) in order.goodsList"
						:key="goodsIndex"
						style="border-bottom: 1px solid #ededed; padding-left: 10px; display: flex; align-items: center; position: relative; padding-bottom: 10px"
					>
						<div style="flex-shrink: 0; margin: 0 10px">
							<img :src="getImgUrl(item.goodsLogo)" alt="" style="width: 90px; height: 90px; border-radius: 5px" />
						</div>

						<div class="" style="flex-grow: 1">
							<div>
								<div>{{ item.goodsName }}</div>
								<div style="color: #666; padding: 8px 2px; margin-top: 0px">
									{{ getInstanceDesc(item, false) }}
								</div>
								<div style="margin: 0px; overflow: hidden; padding: 5px 0px">
									<span class="goodsPrice" style="float: left; right: 10px; display: block; font-size: 14px; color: #c00; color: red; font-weight: bold">
										<b>￥</b>
										{{ qy.formatMoney(item.goodsDealerPrice > 0 ? item.goodsDealerPrice : item.goodsPrice) }}
									</span>
									<span style="margin-left: 12px; color: #999">
										<i class="van-icon van-icon-cross prt2" />
										{{ item.goodsNum }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style="text-align: right; font-size: 14px; font-weight: bold; letter-spacing: 1px; padding-top: 10px; padding-right: 10px; color: red">
					订单总价
					<span style="font-size: 12px">¥</span>
					{{ qy.formatMoney(order.orderMoney) }}
				</div>
			</template>
		</div>

		<div class="orderBase" v-if="order.status == 2 || order.status == 3">
			<h5 class="title">物流信息</h5>
			<div>
				<label>物流公司：</label>
				<span>{{ order.freightName || '未填' }}</span>
			</div>
			<div>
				<label>物流单号：</label>
				<span>{{ order.freightNo || '未填' }}</span>
			</div>
		</div>
		<div class="orderBase">
			<h5 class="title">订单信息</h5>
			<div>
				<label>订单编号：</label>
				<span>{{ order.orderNo }}</span>
			</div>

			<div>
				<label>订单备注：</label>
				<span>{{ order.remark || '' }}</span>
			</div>
			<div>
				<label>创建时间：</label>
				<span>{{ createTime }}</span>
			</div>
			<div v-if="order.status > 1">
				<label>发货时间：</label>
				<span>{{ sendTime }}</span>
			</div>
			<div v-if="order.status > 2">
				<label>签收时间：</label>
				<span>{{ signTime }}</span>
			</div>
		</div>
		<div style="height: 50px"></div>
		<div class="button_bottom">
			<div
				style="
					text-align: right;
					font-size: 15px;
					font-weight: bold;
					color: red;
					letter-spacing: 1px;
					padding-right: 10px;
					display: inline-block;
					float: left;
					padding-left: 10px;
					line-height: 30px;
				"
			>
				<span>订单总价：</span>
				<span style="font-size: 12px">¥</span>
				{{ qy.formatMoney(order.orderMoney) }}
			</div>
			<div style="margin-right: 5px; display: inline-block" v-show="selfOrder || isProxy()">
				<van-button v-if="order.status == 0" type="danger" round size="mini" @click.stop="shareOrder()" style="width: 70px">好友代付</van-button>
			</div>
			<div style="display: inline-block" v-show="selfOrder || !isProxy()">
				<van-button v-if="order.status == 0" type="danger" round size="mini" @click.stop="openPayPanel()" style="width: 70px">支付</van-button>
			</div>
			<!-- <van-button size="mini" plain round type="info"
				@click.stop="addShopCart(item.goodsInstanceId,item.goodsDealerPrice)">加入购物车
			</van-button> -->
		</div>

		<!--支付方式-->
		<van-popup v-model="showPayPanel" position="bottom" closeable close-icon="close" round :style="{ height: 'auto' }" id="payTypePopup">
			<div style="text-align: center; padding: 10px 0px; font-size: 18px">选择支付方式</div>
			<van-divider />
			<ul>
				<li :class="{ active: payType == 0 }" @click="getPayType(0)" style="position: relative">
					<span style="">{{ isInWx() ? '微信支付' : '支付宝' }}</span>
					<span v-if="balanceInfo.balance&&!isInWxMini" style="position: absolute; right: 0; margin-right: 0.2rem">
						<label>
							余额：
							<span style="color: red; font-size: 16px; margin-right: 5px">{{ formatMoney(balanceInfo.balance) }}</span>
						</label>
						<van-switch  class="prt2" v-model="useBalance" size="14px" />
					</span>
				</li>
				<!-- <li :class="{active:payType==1}" @click="getPayType(1)">线下支付</li> -->
				<!-- <li :class="{active:payType==2}" @click="getPayType(2)">物流代收</li> -->
			</ul>
			<div style="padding: 1.2% 5%">
				<van-button block plain round size="normal" type="danger" @click="payOrder()">确认支付</van-button>
			</div>
		</van-popup>
		<van-overlay :show="showShare" @click="showShare = false" z-index="100" />
		<div v-show="showShare" class="qy-center" style="padding: 20px; background-color: #fff; border-radius: 6px"><qr-code :appSrc="qrCode" :size="200"></qr-code></div>
	</div>
</template>
<script type="text/javascript">
import request from '@/utils/request';
import QrCode from '@/views/qrCode/qrCode';
export default {
	name: 'orderDetail',
	components: {
		QrCode
	},
	data() {
		return {
			showOverTime: false,
			containerHeight: 0,
			orderId: -1,
			overTime: 0,
			overTimeText: '0小时0分0秒',
			shop: null,
			timer: null,
			statusDesc: {
				0: "待付款",
				1: "待发货",
				2: "待签收",
				3: "已完成",
				4: "退货处理中",
				5: "已退货",
				11: "已取消",
				12: "已超时"
			},
			address: {
				id: '1',
				name: '张三',
				tel: '13000000000',
				address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室'
			},
			order: {
				proxyInfo: {},
				detailList: [],
				transferList: []
			},
			createTime: null,
			payTime: null,
			sendTime: null,
			signTime: null,
			payType: 0,
			useBalance: 0,
			showPayPanel: false,
			useBalance: false,
			isInWxMini: false,
			selfOrder: false,
			showShare: false,
			orderUser: {},
			qrCode: '',
			balanceInfo: {}
		};
	},
	created() {
		let that = this;
		this.isInWxMiniPro((rs)=>{
			this.isInWxMini=rs;
		});
		this.orderId = this.getUrlParam('orderId') || '';
		let orderNo = this.getUrlParam('orderNo') || '';
		console.log(orderNo)
		request.post('/api/order/detail', {
				orderId: this.orderId,
				orderNo: orderNo
			}).then((rs) => {
				if (rs.success && rs.data) {
					let { detailList, order, transferList, balance } = rs.data;
					this.qrCode = decodeURIComponent(location.origin + '/orderDetail?orderNo=' + order.orderNo);
					console.log(this.qrCode)
					this.balanceInfo = balance;
					order.goodsList = detailList;
					order.transferList = transferList;
					this.order = order;
					this.shop = rs.data.shop;
					this.order.shopName = this.shop ? this.shop.shopName : '平台';
					this.overTime = order.overTime || 0; //超时秒
					this.orderId = order.id;
					this.order.transferList.forEach((item) => {
						switch (item.operCode) {
							case 1:
								that.createTime = item.createTime;
								return false;
							case 2:
								that.sendTime = item.createTime;
								return false;
							case 3:
								that.signTime = item.createTime;
								return false;
						}
					});
					this.selfOrder = rs.data.selfOrder;
					this.orderUser = rs.data.orderUser || null;
					if (this.getUrlParam('openPay') == 1 && order.status == 0) {
						if (rs.data.selfOrder) {
							this.openPayPanel();
						} else {
							this.alert('下单成功,请提示客户支付!');
						}
					}
					this.timer = setInterval(this.getOverTime, 1000);
				}
			});
	},
	methods: {
		addShopCart(goodsInstanceId, addPrice) {
			request.post('/api/shopCart/add', {
					goodsInstanceId: goodsInstanceId,
					addPrice: addPrice,
					goodsNum: 1
				}).then((rs) => {
					this.Toast('加入成功');
				});
		},
		goodsDetail(goodsId) {
			this.$router.push('/goodsInfo?goodsId=' + goodsId);
		},
		toShopDetail() {
			if (this.shop.shopId <= 0) {
				return;
			}
			this.$router.push({
				path: '/shop',
				query: {
					id: this.shop.shopId
				}
			});
		},
		formatSeconds(value) {
			var theTime = parseInt(value); // 秒
			var theTime1 = 0; // 分
			var theTime2 = 0; // 小时
			if (theTime > 60) {
				theTime1 = parseInt(theTime / 60);
				theTime = parseInt(theTime % 60);
				if (theTime1 > 60) {
					theTime2 = parseInt(theTime1 / 60);
					theTime1 = parseInt(theTime1 % 60);
				}
			}
			var result = '' + parseInt(theTime) + '秒';
			if (theTime1 > 0) {
				result = '' + parseInt(theTime1) + '分' + result;
			}
			if (theTime2 > 0) {
				result = '' + parseInt(theTime2) + '小时' + result;
			}
			return result;
		},
		getOverTime() {
			if (this.overTime > 0) {
				this.overTime--;
				this.overTimeText = this.formatSeconds(this.overTime);
				this.showOverTime = this.overTime > 0;
			} else {
				this.showOverTime = false;
			}
		},
		shareOrder() {
			this.showShare = true;
		},
		openPayPanel() {
			if (this.isInWxMini) {
				//跳转到微信支付
				this.openWxMiniPay({
					orderId: this.orderId,
					type: 1,
					callback: 'orderList?status=-1'
				});
				return;
			}
			// this.orderId = orderId;
			this.showPayPanel = true;
			
		},
		getPayType(v) {
			this.payType = v;
		},
		payOrder() {
			
			//线上支付-使用余额？
			let useBalance = 0;
			if (this.payType == 0) {
				useBalance = this.useBalance ? 1 : 0;
			}
			request.post('/api/order/payOrder', {
					orderId: this.orderId,
					payType: this.payType,
					useBalance: useBalance,
					redirectUrl: 'https://' + location.hostname + '/orderList'
				}).then((rs) => {
					if (rs.success) {
						let data = rs.data;
						if (!data) {
							this.Toast.success('支付成功');
							this.reloadPage();
						} else {
							
							this.openWxH5Pay(decodeURIComponent(data.payData));
						}
					} else {
						return this.Toast(rs.msg);
					}
				});
			this.showPayPanel = false;
		}
	},
	mounted() {
		this.containerHeight = this.getWinHeight() - 50 + 'px';
	},
	beforeDestroy() {
		clearInterval(this.timer);
	}
};
</script>
<style type="text/css" scoped="">
.orderImg img {
	width: 90px;
	height: 90px;
	border-radius: 5px;
}

.orderGoodsContainer {
	margin: 5px;
	border-radius: 5px;
	background: #fff;
	padding-bottom: 12px;
}

.goodsTitle {
	padding-right: 10px;
	position: relative;
	font-size: 12px;
}

.goodsTitle .p1 {
	font-weight: bold;
	margin-bottom: 0px;
}

.goodsTitle .p2 {
	color: #9c9c9c;
	background: #fbfbfb;
	padding: 4px 2px;
}

.goodsPrice {
	float: left;
	right: 10px;
	display: block;
	font-size: 14px;
	color: #c00;
}

.goodsNum {
	float: right;
	color: #999;
}

.goodsNum .van-icon {
	position: relative;
	top: 2px;
}

.goodsPrice {
	font-weight: bold;
}

.orderTitle .van-icon {
	position: relative;
	top: 3px;
}

.orderTitle .van-icon-send-gift {
	color: red;
	margin: 0 3px;
	font-size: 25px;
	top: 6px;
}

.p2 {
	clear: both;
	margin: 5px 0px;
}

.goodsList {
	padding-bottom: 20px;
	overflow: auto;
}

.listDetails {
	display: flex;
	padding-left: 10px;
}

.button_bottom {
	text-align: right;
	padding-right: 10px;
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 100;
	background: #fff;
	padding: 6px 10px;
	border-top: 1px solid #ededed;
}

.orderBase {
	border-radius: 5px;
	background: #fff;
	padding: 5px 0;
	margin: 12px 5px;
}

.p1 {
	font-size: 14px;
	margin-top: 0px;
}

.orderBase h5 {
	margin: 10px 0;
	padding-left: 9px;
	font-size: 15px;
	border-bottom: 1px solid #ededed;
	padding-bottom: 6px;
}

.orderBase > div {
	display: flex;
	font-size: 13px;
	margin-bottom: 10px;
}

.orderBase > div > label {
	width: 70px;
	padding-left: 10px;
	position: relative;
	top: -2px;
}

.goodsPrice b {
	font-size: 12px;
}

.bottomOper {
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin: 0px 25px 20px; */
	border-top: 1px solid rgb(238, 238, 238);
	padding: 10px;
	background: #fff;
}

.over-time-cancel {
}

.over-time-cancel > span {
	color: red;
}

.pricelist {
	margin: 0px;
	overflow: hidden;
	padding: 5px 0px;
}

/* .van-button {
		background: -webkit-linear-gradient(left, #ff6034, #ee0a24);
		background: linear-gradient(to right, #ff6034, #ee0a24);
		border: 0px;
		color: #fff;
		padding: 0px 15px;
		line-height: 35px;
		height: 35px;
		font-size: 14px;
	} */

#payTypePopup ul {
	font-size: 14px;
	margin-bottom: 0.1rem;
}

#payTypePopup > ul > li {
	text-align: center;
	padding: 12px 0px;
	margin: 0px;
	border-bottom: 1px #ddd solid;
	border-radius: 5px;
}

#payTypePopup > ul > li.active {
	background-color: #f7f7f7;
	color: #333;
}
</style>
